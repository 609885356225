import React from "react";
import Accordion from 'react-bootstrap/Accordion';


const Services = () => {
    return (
        <div id="services" className="accordion" style={{backgroundColor: "#fbfbfb"}}>

            <div className="area-2">
                
                
            
                <div className="accordion-container" id="accordionOne">
                    <h2>Services</h2>
                    <Accordion defaultActiveKey="0" >
                        <Accordion.Item eventKey="0" className="item" aria-labelledby="headingOne" data-parent="#accordionOne">
                            <Accordion.Header elementType="div">
                                <div>
                                    <span className="circle-numbering">1</span><span className="accordion-title">Life Coaching</span>
                                </div>
                            </Accordion.Header>
                            <Accordion.Body className="accordion-body">
                                Our certified life coaches are committed to helping individuals navigate life's challenges with mindfulness and compassion. Through one-on-one sessions, we provide a safe and supportive space for personal growth, self-discovery, and the development of practical strategies for achieving life goals.
                            </Accordion.Body>
                        </Accordion.Item>
                        
                        <Accordion.Item eventKey="1" className="item" aria-labelledby="headingOne" data-parent="#accordionOne">
                            <Accordion.Header elementType="div">
                                <div>
                                    <span className="circle-numbering">2</span><span className="accordion-title">Leadership Coaching</span>
                                </div>
                            </Accordion.Header>
                            <Accordion.Body className="accordion-body">
                            Our leadership coaching programs are tailored to empower executives, managers, and emerging leaders. We emphasize the integration of mindfulness and compassion in leadership styles, equipping professionals with the tools to inspire teams, navigate change, and foster a culture of innovation.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="2" className="item" aria-labelledby="headingOne" data-parent="#accordionOne">
                            <Accordion.Header elementType="div">
                                <div>
                                    <span className="circle-numbering">3</span><span className="accordion-title">Consulting</span>
                                </div>
                            </Accordion.Header>
                            <Accordion.Body className="accordion-body">
                            We partner with organizations to bring a mindful and compassionate lens to their business strategies. Our consulting services focus on creating workplace environments that prioritize well-being, resilience, and effective communication, ultimately enhancing employee satisfaction and organizational success.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="3" className="item" aria-labelledby="headingOne" data-parent="#accordionOne">
                            <Accordion.Header elementType="div">
                                <div>
                                    <span className="circle-numbering">4</span><span className="accordion-title">Workshops and Retreats</span>
                                </div>
                            </Accordion.Header>
                            <Accordion.Body className="accordion-body">
                                Immerse yourself in our transformative workshops and retreats designed to deepen your understanding of mindfulness and compassion. These experiences provide practical tools for stress reduction, improved focus, and enhanced emotional intelligence.
                            </Accordion.Body>
                        </Accordion.Item>

                    </Accordion>


                
            </div> 

        </div> 
        <div className="area-1">
            
            </div>
    </div>
    );
  };
   
  export default Services;