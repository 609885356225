import React,{useEffect} from "react";
import ReactTextRotator from 'react-text-rotator';
import { HashLink  } from 'react-router-hash-link';

function Intro() {


  const [width, setWidth] = React.useState(window.innerWidth);
  const breakpoint = 620;
  useEffect(() => {
    const handleWindowResize = () => setWidth(window.innerWidth)
    window.addEventListener("resize", handleWindowResize);

    // Return a function from the effect that removes the event listener
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

    const subtitle = [
        {
          text: "Educational Consultant",
    
          animation: "fade",
        },
        {
          text: "Mindfulness Practitioner",
    
          animation: "fade",
        },
        {
          text: "Life Coach",
    
          animation: "fade",
        },
        {
          text: "Executive Leadership Coach",
    
          animation: "fade",
        },
    
        {
            text: "Joyologist",
      
            animation: "fade",
          },
    
        
      ];
      
    return (
        <header id="header" className="header">
        <div className="header-content">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="text-container">
                            {width > breakpoint && <h1 style= { {font: `200 3.5rem/4rem "Montserrat"`} }>THOMPSON COACHING & CONSULTING</h1>}
                            <span className="p-heading p-large">    
                                <ReactTextRotator className="p-heading p-large"   content={subtitle}  time={3000} startDelay={1000}  /> 
                            </span>
                            <br />
                            <HashLink className="btn-solid-lg page-scroll" smooth to="/#aboutcc">Learn more</HashLink>

                        </div>
                    </div> 
                </div> 
            </div> 
        </div> 
    </header>
    );
  };
   
  export default Intro;