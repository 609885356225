import React from "react"
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import Header from "./views/shared/header/Header"
import Footer from "./views/shared/footer/Footer"
import Home from './views/home/Main'
import PrivacyPolicy from './views/terms/privacy-policy'



export default function Routes() {


  return(

      <BrowserRouter>
        <Header />

          <Switch>
              <Route path="/" exact component={Home} />
              <Route path="/privacy-policy" exact component={PrivacyPolicy} />
          </Switch>

        <Footer />
      </BrowserRouter>
  )
}
