import { combineReducers } from "redux";
import PaymentReducer from "./PaymentReducer";
// import CredentialReducer from "./CredentialsReducer"
// import AlertReducer from "./AlertReducer"

const RootReducer = combineReducers({
  payment: PaymentReducer,
//   credential: CredentialReducer,
//   alert: AlertReducer
});

export default RootReducer;
