import React from 'react';
import { NavHashLink } from 'react-router-hash-link'



function Header() {

//   const [scrolling, setScrolling] = React.useState(false);
  const [scrollTop, setScrollTop] = React.useState(0);
  const [openMobileMenu, setOpenMobileMenu] = React.useState(false);


  
  const onScroll = (e) => {
    setScrollTop(e.target.documentElement.scrollTop);
    // setScrolling(e.target.documentElement.scrollTop > scrollTop);
  }

  React.useEffect(() => {
    window.addEventListener('scroll', onScroll);
  },[]);


  const logo = {

    "font": `200 1.25rem/1.5rem "Nunito"`
  }



  return (
    <>
    
    <nav className={`navbar navbar-expand-md navbar-dark navbar-custom fixed-top ${scrollTop > 20 && 'top-nav-collapse'}`}>
       
    {scrollTop >20 && 
        <NavHashLink smooth className="navbar-brand logo-text page-scroll" style ={logo} to="/#">NATASHA THOMPSON</NavHashLink>
    }
     {scrollTop >20 && 
        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarsExampleDefault" aria-controls="navbarsExampleDefault" aria-expanded={openMobileMenu} aria-label="Toggle navigation" onClick={() => setOpenMobileMenu(!openMobileMenu)}>
            <span className="navbar-toggler-awesome fas fa-bars"></span>
            <span className="navbar-toggler-awesome fas fa-times"></span>
        </button>
        }
        
        {/* <!-- end of mobile menu toggle button --> */}

        <div className={`collapse  ${!openMobileMenu  ? 'navbar-collapse' : 'show '}`} id="navbarsExampleDefault">
            <ul className="navbar-nav ml-auto">
                <li className="nav-item">
                    <NavHashLink smooth className="nav-link page-scroll" to="/#">HOME <span className="sr-only">(current)</span></NavHashLink>
                </li>
                <li className="nav-item">
                    <NavHashLink smooth className="nav-link page-scroll" to="/#aboutcc">ABOUT THOMPSON C&C</NavHashLink>
                </li>
                <li className="nav-item">
                    <NavHashLink smooth className="nav-link page-scroll" to="/#services">SERVICES</NavHashLink>
                </li>
                <li className="nav-item">
                    <NavHashLink smooth className="nav-link page-scroll" to="/#aboutnatasha">ABOUT NATASHA</NavHashLink>
                </li>
                <li className="nav-item">
                    <NavHashLink smooth className="nav-link page-scroll" to="/#testimonials">TESTIMONIALS</NavHashLink>
                </li>

                <li className="nav-item">
                    <NavHashLink smooth className="nav-link page-scroll" to="/#schedule">SCHEDULE A MEETING</NavHashLink>
                </li>

                <li className="nav-item">
                    <NavHashLink smooth className="nav-link page-scroll" to="/#contact">LET'S CONNECT</NavHashLink>
                </li>

            </ul>
            <span className="nav-item social-icons">
                <span className="fa-stack">
                    <a target="_blank" rel="noreferrer" href="https://twitter.com/coachnatasha">
                        <span className="hexagon"></span>
                        <i className="fab fa-twitter fa-stack-1x"></i>
                    </a>
                </span>
                <span className="fa-stack">
                    <a target="_blank" rel="noreferrer" href="https://www.linkedin.com/in/natashafayettethompson/">
                        <span className="hexagon"></span>
                        <i className="fab fa-linkedin-in fa-stack-1x"></i>
                    </a>
                </span>
            </span>
        </div>
   
    </nav> 
    {/* <!-- end of navbar --> */} 


  </>
  );
}


export default Header;