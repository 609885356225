import React from "react";


import Schedule from "./Schedule"
import Intro from './Intro'
// import AboutMe from './About'
import AboutNatasha from  './AboutNatasha'
import Services from './Services'
// import Certifications from './Certifications'
// import Plans from './Plans'
import AboutCompany from './AboutThompson'
import Contact from './Contact'
import Testimonials from './Testimonials'




export default function Home()  {

    
   



    return (
        <>

            <Intro />
            
            <AboutCompany  />

            <Services />
            {/* <AboutMe />  */}
            <AboutNatasha />
            <Testimonials />
            {/* <Plans /> */}
            {/* <Certifications /> */}
            <Schedule />
            <Contact />



        </>
        
      );
  };

