import React from "react";
import {Tabs, Tab} from "react-bootstrap";


const AboutNatasha = () => {
    return (
    <div id="aboutnatasha" className="tabs">
        
        <div className="area-1">
            
            <div className="tabs-container2">
                <h2 className="title">About Natasha Thompson</h2>
                <br/>
                <p style={{marginLeft: "20px", marginRight:"20px"}}>Welcome to the <strong>transformative world of mindfulness and compassionate leadership with Natasha Thompson</strong>, a seasoned professional with a passion for guiding individuals and organizations toward a more purposeful and harmonious existence.</p>
                <p style={{marginLeft: "20px", marginRight:"20px"}}><strong>Biography: </strong>Natasha Thompson is a certified mindfulness life and leadership coach, bringing a wealth of experience and wisdom to her clients. With a background in education, Natasha's journey into mindfulness and compassion was sparked by a desire to empower others to lead fulfilling lives and navigate the complexities of leadership with grace.</p>
                <div className="avatarcontainer"> 
                    <img src="/img/me/Natasha Thompson-130 EDITCROPPED.jpg" alt="NatashaThompson" className="avatar" />
                </div>
                
            </div>
        </div> 
        <div className="area-2">
        
            <div className="tabs-container">
                
        
            <br />
                {/* <ul className="nav nav-tabs" id="ariaTabs" role="tablist">
                    <li className="nav-item">
                        <a className="nav-link active" id="nav-tab-1" data-toggle="tab" href="#tab-1" role="tab" aria-controls="tab-1" aria-selected="true"><i className="fas fa-th"></i> Business</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" id="nav-tab-2" data-toggle="tab" href="#tab-2" role="tab" aria-controls="tab-2" aria-selected="false"><i className="fas fa-th"></i> Expertise</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" id="nav-tab-3" data-toggle="tab" href="#tab-3" role="tab" aria-controls="tab-3" aria-selected="false"><i className="fas fa-th"></i> Quality</a>
                    </li>
                </ul> */}
                <Tabs defaultActiveKey={1} id="ariaTabs" className="nav nav-tabs">
                    <Tab className="nav-item" eventKey={1} title="Mission">
                        <div className="tab-pane" id="tab-1" role="tabpanel" aria-labelledby="tab-1">
                        {/* <h4>Mission Statement</h4> */}
                        <p>Natasha is on a mission to spread the transformative power of mindfulness and compassion. Her goal is to empower individuals and organizations to cultivate a positive and resilient mindset, fostering joy, harmony, and success.</p>
                        
                        
                    </div> 
                    </Tab>
                    <Tab className="nav-item" eventKey={2} title="Philosophy">
                        <p>Natasha's coaching philosophy revolves around the principles of mindfulness, compassion, and ancient wisdom. She believes that by embracing these core values, individuals can unlock their full potential, leading more fulfilling and purpose-driven lives.</p>
                    </Tab>
                    <Tab className="nav-item" eventKey={3} title="Credentials">
                        <div className="tab-pane" id="tab-3" role="tabpanel" aria-labelledby="tab-3">
                            <div className="row">
                                <div className="col-lg-12">
                                    <ul className="list-unstyled li-space-lg">
                                        <li className="media">
                                            <i className="fas fa-square"></i>
                                            <div className="media-body"><strong>Certified Mindfulness Life and Leadership Coach: </strong>Natasha has undergone rigorous training in mindfulness coaching, equipping her with the skills to guide individuals and teams towards self-discovery, resilience, and goal attainment.</div>
                                        </li>
                                        <li className="media">
                                            <i className="fas fa-square"></i>
                                            <div className="media-body"><strong>Meditator: </strong>Natasha's personal meditation practice forms the foundation of her coaching philosophy, promoting clarity, focus, and a deeper connection to the present moment.</div>
                                        </li>

                                        {/* <li className="media">
                                            <i className="fas fa-square"></i>
                                            <div className="media-body"><strong>Facilitator Joyologist: </strong>As a joyologist, Natasha specializes in creating joyful and uplifting experiences for individuals and groups, infusing positivity into every interaction.</div>
                                        </li> */}

                                        <li className="media">
                                            <i className="fas fa-square"></i>
                                            <div className="media-body"><strong>Educator: </strong>With a background in education, Natasha brings a pedagogical approach to her coaching, ensuring that clients not only learn but also integrate mindfulness principles into their daily lives.</div>
                                        </li>

                                        <li className="media">
                                            <i className="fas fa-square"></i>
                                            <div className="media-body"><strong>Compassionate Leader: </strong>Natasha's leadership style is grounded in empathy, kindness, and understanding. She believes in leading by example and creating environments that nurture the growth and well-being of those around her.</div>
                                        </li>

                                        <li className="media">
                                            <i className="fas fa-square"></i>
                                            <div className="media-body"><strong>Ancient Wisdom Practitioner: </strong>Natasha draws inspiration from ancient wisdom traditions, integrating timeless teachings into modern coaching practices for a holistic and profound impact.</div>
                                        </li>

                                    </ul>
                                    
                                </div> 
                            </div> 

                        </div> 
                    </Tab>
                    </Tabs>

                <div className="tab-content" id="ariaTabsContent">

            


     



                </div> 


            </div> 
        </div>
    </div> 

    );
    }
  export default AboutNatasha;