import React, {useState} from 'react';
import {Link} from 'react-router-dom'
import {Sendmail} from "../../redux/actions/MailActions"

export default function PPolicy() {

    const [contactInfo, setContactInfo] = useState({
        subject: 'Natasha Thomspon | Privacy Policy',
        name: '',
        mail: '',
        message: ''
    });

    const handleSubmit = (contactInfo, e) => {
        e.preventDefault();
        Sendmail(contactInfo,e)

        setContactInfo({
            subject : 'Natasha Thomspon | Privacy Policy',
            name: '',
            mail: '',
            message: ''
          })
      ;
    }

    function updateState(e){
        e.persist()
        // console.log(e.target.value)
        // console.log(e.target.id)
    
        setContactInfo({
          ...contactInfo,
          [e.target.id]: e.target.value,
          [e.target.name]: e.target.value
        })
      }


    


  return (
    <>

    <header id="#" className="ex-header">
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <h1>Privacy Policy</h1>
                </div> 
            </div> 
        </div> 
    </header> 




    <div className="ex-basic-2">
        <div className="container">
            <div className="row">
                <div className="col-lg-12">
                    <div className="text-container">
                        <h3>Private Data We Receive And Collect</h3>
                        <p>Effective date: Dec 13, 2023</p>
                        <p> Thompson Coaching & Consulting Company, Inc. (“us”, “we”, or “our”) operates the https://natashathompson.com website (the “Service”).</p>
                        <p>This page informs you of our policies regarding the collection, use, and disclosure of personal data when you use our Service and the choices you have associated with that data.</p>
                        <p>We use your data to provide and improve the Service. By using the Service, you agree to the collection and use of information in accordance with this policy. Unless otherwise defined in this Privacy Policy, terms used in this Privacy Policy have the same meanings as in our Terms and Conditions, accessible from https://natashathompson.com</p>
                    </div>
                        
                    <div className="text-container">
                        <h3>Definitions</h3>
                        <div className="col-md-12">
                        <ul className="list-unstyled li-space-lg indent">
                            <li className="media">
                                <i className="fas fa-square"></i>
                                <div className="media-body"><b>Service</b> is the https://natashathompson.com website operated by Thompson Coaching & Consulting Company, Inc.</div>
                            </li>

                            <li className="media">
                                <i className="fas fa-square"></i>
                                <div className="media-body"><b>Personal Data</b> means data about a living individual who can be identified from those data (or from those and other information either in our possession or likely to come into our possession).</div>
                            </li>

                            <li className="media">
                                <i className="fas fa-square"></i>
                                <div className="media-body"><b>Usage Data </b> is data collected automatically either generated by the use of the Service or from the Service infrastructure itself (for example, the duration of a page visit).</div>
                            </li>

                            <li className="media">
                                <i className="fas fa-square"></i>
                                <div className="media-body"><b>Cookies</b> are small pieces of data stored on your device (computer or mobile device).</div>
                            </li>

                            <li className="media">
                                <i className="fas fa-square"></i>
                                <div className="media-body"><b>Data Controller </b> means the natural or legal person who (either alone or jointly or in common with other persons) determines the purposes for which and the manner in which any personal information are, or are to be, processed.For the purpose of this Privacy Policy, we are a Data Controller of your Personal Data.</div>
                            </li>

                            <li className="media">
                                <i className="fas fa-square"></i>
                                <div className="media-body"><b>Data Processor (or Service Provider)</b> means any natural or legal person who processes the data on behalf of the Data Controller.We may use the services of various Service Providers in order to process your data more effectively.</div>
                            </li>

                            <li className="media">
                                <i className="fas fa-square"></i>
                                <div className="media-body"><b>Data Subject</b> is any living individual who is using our Service and is the subject of Personal Data.</div>
                            </li>

                        </ul>
                    </div>



                    <div className="text-container">
                        <h3>Information Collection And Use</h3>
                        <p>We collect several different types of information for various purposes to provide and improve our Service to you.</p>
                        <h4>Types of Data Collected</h4>
                        <h5>Personal Data</h5>
                        <p>While using our Service, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you (“Personal Data”). Personally identifiable information may include, but is not limited to:</p>
                        
                        <div className="col-md-12">
                        <ul className="list-unstyled li-space-lg indent">
                            <li className="media">
                                <i className="fas fa-square"></i>
                                <div className="media-body">Email address</div>
                            </li>

                            <li className="media">
                                <i className="fas fa-square"></i>
                                <div className="media-body">First name and last name</div>
                            </li>

                            <li className="media">
                                <i className="fas fa-square"></i>
                                <div className="media-body">Cookies and Usage Data</div>
                            </li>                                  
                        </ul>
                        </div>
                        <p>We may use your Personal Data to contact you with newsletters, marketing or promotional materials and other information that may be of interest to you. You may opt out of receiving any, or all, of these communications from us by following the unsubscribe link or instructions provided in any email we send.</p>
                        
                        <h5>Usage Data</h5>
                        <p>We may also collect information how the Service is accessed and used (“Usage Data”). This Usage Data may include information such as your computer’s Internet Protocol address (e.g. IP address), browser type, browser version, the pages of our Service that you visit, the time and date of your visit, the time spent on those pages, unique device identifiers and other diagnostic data.</p>
                    
                        <h5>Tracking & Cookies Data</h5>
                        <p>We use cookies and similar tracking technologies to track the activity on our Service and hold certain information.</p>
                        <p>Cookies are files with small amount of data which may include an anonymous unique identifier. Cookies are sent to your browser from a website and stored on your device. Tracking technologies also used are beacons, tags, and scripts to collect and track information and to improve and analyze our Service.</p>
                        <p>You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some portions of our Service.</p>
                        <p>Examples of Cookies we use:</p>
                        <ul className="list-unstyled li-space-lg indent">
                            <li className="media">
                                <i className="fas fa-square"></i>
                                <div className="media-body"><b>Session Cookies</b> We use Session Cookies to operate our Service.</div>
                            </li>

                            <li className="media">
                                <i className="fas fa-square"></i>
                                <div className="media-body"><b>Preference</b> Cookies We use Preference Cookies to remember your preferences and various settings.</div>
                            </li>

                            <li className="media">
                                <i className="fas fa-square"></i>
                                <div className="media-body"><b>Security Cookies</b> We use Security Cookies for security purposes.</div>
                            </li>                                  
                        </ul>
                    
                    </div>


                    <div className="text-container">
                        <h3>Use of Data</h3>
                        <p>Thompson Coaching & Consulting Company, Inc uses the collected data for various purposes:</p>
                        <div className="col-md-12">
                        <ul className="list-unstyled li-space-lg indent">
                            <li className="media">
                                <i className="fas fa-square"></i>
                                <div className="media-body">To provide and maintain our Service</div>
                            </li>

                            <li className="media">
                                <i className="fas fa-square"></i>
                                <div className="media-body">To notify you about changes to our Service</div>
                            </li>

                            <li className="media">
                                <i className="fas fa-square"></i>
                                <div className="media-body">To allow you to participate in interactive features of our Service when you choose to do so</div>
                            </li>

                            <li className="media">
                                <i className="fas fa-square"></i>
                                <div className="media-body">To provide customer support</div>
                            </li>

                            <li className="media">
                                <i className="fas fa-square"></i>
                                <div className="media-body">To gather analysis or valuable information so that we can improve our Service</div>
                            </li>

                            <li className="media">
                                <i className="fas fa-square"></i>
                                <div className="media-body">To monitor the usage of our Service</div>
                            </li>

                            <li className="media">
                                <i className="fas fa-square"></i>
                                <div className="media-body">To detect, prevent and address technical issues</div>
                            </li>

                            <li className="media">
                                <i className="fas fa-square"></i>
                                <div className="media-body">To provide you with news, special offers and general information about other goods, services and events which we offer that are similar to those that you have already purchased or enquired about unless you have opted not to receive such information</div>
                            </li>
                            <li className="media">
                                <i className="fas fa-square"></i>
                                <div className="media-body">Your billing address and any necessary other information to complete any financial transaction, and when making purchases through the Services, we may also collect your credit card or PayPal information</div>
                            </li>
                            <li className="media">
                                <i className="fas fa-square"></i>
                                <div className="media-body">User generated content (such as messages, posts, comments, pages, profiles, images, feeds or communications exchanged on the Supported Platforms)</div>
                            </li>
                            <li className="media">
                                <i className="fas fa-square"></i>
                                <div className="media-body">Images or other files that you may publish via our Services</div>
                            </li>
                            <li className="media">
                                <i className="fas fa-square"></i>
                                <div className="media-body">Information (such as messages, posts, comments, pages, profiles, images) we may receive relating to communications you send us, such as queries or comments concerning</div>
                            </li>

                        </ul>

                        </div>
                    </div>




                    <div>

                    <div className="col-md-6">
                        <ul className="list-unstyled li-space-lg indent">
                            
                        </ul>
                    </div> 
                </div> 
            </div> 
                    
            <div className="text-container">
                <h3>Legal Basis for Processing Personal Data Under General Data Protection Regulation (GDPR)</h3>
                <p>If you are from the European Economic Area (EEA), Natasha hompson Consulting Company, Inc. legal basis for collecting and using the personal information described in this Privacy Policy depends on the Personal Data we collect and the specific context in which we collect it.</p>
                <p>Natasha hompson Consulting Company, Inc. may process your Personal Data because:</p>
                <div className="col-md-12">
                    <ul className="list-unstyled li-space-lg indent">
                        <li className="media">
                            <i className="fas fa-square"></i>
                            <div className="media-body">We need to perform a contract with you</div>
                        </li>
                        <li className="media">
                            <i className="fas fa-square"></i>
                            <div className="media-body">You have given us permission to do so</div>
                        </li>
                        <li className="media">
                            <i className="fas fa-square"></i>
                            <div className="media-body">The processing is in our legitimate interests and it’s not overridden by your rights</div>
                        </li>
                        <li className="media">
                            <i className="fas fa-square"></i>
                            <div className="media-body">To comply with the law</div>
                        </li>
                    </ul>
                </div>

            </div> 

            <div className="text-container">
                <h3>Retention of Data</h3>
                <p>Thompson Coaching & Consulting Company, Inc will retain your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use your Personal Data to the extent necessary to comply with our legal obligations (for example, if we are required to retain your data to comply with applicable laws), resolve disputes, and enforce our legal agreements and policies.</p>
                <p>Thompson Coaching & Consulting Company, Inc will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for a shorter period of time, except when this data is used to strengthen the security or to improve the functionality of our Service, or we are legally obligated to retain this data for longer time periods.</p>
            </div> 

            <div className="text-container">
                <h3>Transfer Of Data</h3>
                <p>Your information, including Personal Data, may be transferred to — and maintained on — computers located outside of your state, province, country or other governmental jurisdiction where the data protection laws may differ than those from your jurisdiction.</p>
                <p>If you are located outside United States and choose to provide information to us, please note that we transfer the data, including Personal Data, to United States and process it there.</p>
                <p>Your consent to this Privacy Policy followed by your submission of such information represents your agreement to that transfer.</p>
                <p>Thompson Coaching & Consulting Company, Inc will take all steps reasonably necessary to ensure that your data is treated securely and in accordance with this Privacy Policy and no transfer of your Personal Data will take place to an organization or a country unless there are adequate controls in place including the security of your data and other personal information.</p>
                
                {/* <p className="mb-5">Your information, including Personal Data, may be transferred to — and maintained on — computers located outside of your state, province, country or other governmental jurisdiction where the data protection laws may differ than those from your jurisdiction.</p> */}
            </div> 
            <div className="text-container">
                <h3>Disclosure Of Data</h3>
                <h4>Disclosure for Law Enforcement</h4>
                <p>Under certain circumstances, Thompson Coaching & Consulting Company, Inc may be required to disclose your Personal Data if required to do so by law or in response to valid requests by public authorities (e.g. a court or a government agency).</p>
                <h4>Legal Requirements</h4>
                <p>Thompson Coaching & Consulting Company, Inc may disclose your Personal Data in the good faith belief that such action is necessary to:</p>
                <div className="col-md-12">
                    <ul className="list-unstyled li-space-lg indent">
                        <li className="media">
                            <i className="fas fa-square"></i>
                            <div className="media-body">To comply with a legal obligation</div>
                        </li>
                        <li className="media">
                            <i className="fas fa-square"></i>
                            <div className="media-body">To protect and defend the rights or property of Thompson Coaching & Consulting Company, Inc</div>
                        </li>
                        <li className="media">
                            <i className="fas fa-square"></i>
                            <div className="media-body">To prevent or investigate possible wrongdoing in connection with the Service</div>
                        </li>
                        <li className="media">
                            <i className="fas fa-square"></i>
                            <div className="media-body">To protect the personal safety of users of the Service or the public</div>
                        </li>
                        <li className="media">
                            <i className="fas fa-square"></i>
                            <div className="media-body">To protect against legal liability</div>
                        </li>
                    </ul>
                </div>
            
            </div>

            <div className="text-container">
                <h3>Security Of Data</h3>
                <p>The security of your data is important to us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your Personal Data, we cannot guarantee its absolute security.</p>

            </div>

            <div className="text-container">
                <h3>“Do Not Track” Signals</h3>
                <p>We do not support Do Not Track (“DNT”). Do Not Track is a preference you can set in your web browser to inform websites that you do not want to be tracked.</p>
                <p>You can enable or disable Do Not Track by visiting the Preferences or Settings page of your web browser.</p>
            </div>

            <div className="text-container">
                <h3>Your Data Protection Rights Under General Data Protection Regulation (GDPR)</h3>
                <p>If you are a resident of the European Economic Area (EEA), you have certain data protection rights. Thompson Coaching & Consulting Company, Inc aims to take reasonable steps to allow you to correct, amend, delete, or limit the use of your Personal Data.</p>
                <p>If you wish to be informed what Personal Data we hold about you and if you want it to be removed from our systems, please contact us.</p>
                <p>In certain circumstances, you have the following data protection rights:</p>

                <div className="col-md-12">
                    <ul className="list-unstyled li-space-lg indent">
                        <li className="media">
                            <i className="fas fa-square"></i>
                            <div className="media-body"><b>The right to access, update or to delete the information we have on you.</b> Whenever made possible, you can access, update or request deletion of your Personal Data directly within your account settings section. If you are unable to perform these actions yourself, please contact us to assist you.</div>
                        </li>
                        <li className="media">
                            <i className="fas fa-square"></i>
                            <div className="media-body"><b>The right of rectification.</b> You have the right to have your information rectified if that information is inaccurate or incomplete.</div>
                        </li>
                        <li className="media">
                            <i className="fas fa-square"></i>
                            <div className="media-body"><b>The right to object.</b> You have the right to object to our processing of your Personal Data.</div>
                        </li>
                        <li className="media">
                            <i className="fas fa-square"></i>
                            <div className="media-body"><b>The right of restriction.</b> You have the right to request that we restrict the processing of your personal information.</div>
                        </li>
                        <li className="media">
                            <i className="fas fa-square"></i>
                            <div className="media-body"><b>The right to data portability. </b>You have the right to be provided with a copy of the information we have on you in a structured, machine-readable and commonly used format.</div>
                        </li>
                        <li className="media">
                            <i className="fas fa-square"></i>
                            <div className="media-body"><b>The right to withdraw consent. </b>You also have the right to withdraw your consent at any time where Thompson Coaching & Consulting Company, Inc relied on your consent to process your personal information.</div>
                        </li>
                    </ul>
                </div>
                <p>Please note that we may ask you to verify your identity before responding to such requests.</p>
                <p>You have the right to complain to a Data Protection Authority about our collection and use of your Personal Data. For more information, please contact your local data protection authority in the European Economic Area (EEA).</p>
            
            </div>

            <div className="text-container">
                <h3>Service Providers</h3>
                <p>We may employ third party companies and individuals to facilitate our Service (“Service Providers”), to provide the Service on our behalf, to perform Service-related services or to assist us in analyzing how our Service is used.</p>
                <p>These third parties have access to your Personal Data only to perform these tasks on our behalf and are obligated not to disclose or use it for any other purpose.</p>
                <h4>Analytics</h4>
                <p>We may use third-party Service Providers to monitor and analyze the use of our Service.</p>
                <div className="col-md-12">
                    <ul className="list-unstyled li-space-lg indent">
                        <li className="media">
                            <i className="fas fa-square"></i>
                            <div className="media-body"><b>Google Analytics </b>is a web analytics service offered by Google that tracks and reports website traffic. Google uses the data collected to track and monitor the use of our Service. This data is shared with other Google services. Google may use the collected data to contextualize and personalize the ads of its own advertising network.You can opt-out of having made your activity on the Service available to Google Analytics by installing the Google Analytics opt-out browser add-on. The add-on prevents the Google Analytics JavaScript (ga.js, analytics.js, and dc.js) from sharing information with Google Analytics about visits activity. For more information on the privacy practices of Google, please visit the Google Privacy & Terms web page: http://www.google.com/intl/en/policies/privacy/</div>
                        </li>
                    </ul>
                </div>

            </div>
            <div className="text-container">
                <h3>Links To Other Sites</h3>
                <p>Our Service may contain links to other sites that are not operated by us. If you click on a third party link, you will be directed to that third party’s site. We strongly advise you to review the Privacy Policy of every site you visit.</p>
                <p>We have no control over and assume no responsibility for the content, privacy policies or practices of any third party sites or services.</p>
            </div>
            <div className="text-container">
                <h3>Children’s Privacy</h3>
                <p>Our Service does not address anyone under the age of 18 (“Children”).</p>
                <p>We do not knowingly collect personally identifiable information from anyone under the age of 18. If you are a parent or guardian and you are aware that your Children has provided us with Personal Data, please contact us. If we become aware that we have collected Personal Data from children without verification of parental consent, we take steps to remove that information from our servers.</p>
            </div>

            <div className="text-container">
                <h3>Changes To This Privacy Policy</h3>
                <p>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.</p>
                <p>We will let you know via email and/or a prominent notice on our Service, prior to the change becoming effective and update the “effective date” at the top of this Privacy Policy.</p>
                <p>You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.</p>
            </div>

                                
            <div className="row">
                <div className="col-md-6">
                    <div className="text-container last">
                        <h3>Inquire What Data We Have</h3>
                        {/* <p>Aria Landing Page uses tracking technology on the landing page, in the Applications, and in the Platforms, including mobile application identifiers and a unique Aria user ID to help us recognize you across different Services, to monitor usage and web traffic routing for the Services, and to customize and improve the Services.</p>
                        <p> We do not knowingly collect personally identifiable information from anyone under the age of 18. If you are a parent or guardian and you are aware that your Children has provided us with Personal Data, please contact us. If we become aware that we have collected Personal Data from children without verification of parental consent, we take steps to remove that information from our servers.</p> */}
                    </div> 
                </div> 
                <div className="col-md-6">


                    <div className="form-container">
                        <form id="privacyForm" data-toggle="validator" data-focus="false" onSubmit={(e) => handleSubmit(contactInfo,e)}>
                            <div className="form-group">
                                <input type="text" className="form-control-input" id="name" value={contactInfo.name} required onChange={updateState}/>
                                <label className="label-control" htmlFor="pname">Name</label>
                                <div className="help-block with-errors"></div>
                            </div>
                            <div className="form-group">
                                <input type="email" className="form-control-input" id="mail" value={contactInfo.mail}  required onChange={updateState}/>
                                <label className="label-control" htmlFor="pemail">Email</label>
                                <div className="help-block with-errors"></div>
                            </div>
                            <div className="form-group">
                                <select className="form-control-select" id="message" value={contactInfo.message} required onChange={updateState}>
                                    <option className="select-option" value="" disabled defaultValue>Select Option</option>
                                    <option className="select-option" value="Delete data">Delete my data</option>
                                    <option className="select-option" value="Show me data">Show me my data</option>
                                </select>
                                <div className="help-block with-errors"></div>
                            </div>
                            <div className="form-group checkbox">
                                <input type="checkbox" id="pterms" value="Agreed-to-Terms" required />I have read and agree to Natasha Thompson's <Link to="/privacy-policy">Privacy Policy</Link>
                                <div className="help-block with-errors"></div>
                            </div>
                            <div className="form-group">
                                <button type="submit" className="form-control-submit-button" style={{outline:0}}>SUBMIT</button>
                            </div>
                            <div className="form-message">
                                <div id="pmsgSubmit" className="h3 text-center hidden"></div>
                            </div>
                        </form>
                    </div> 

                </div>  
            </div> 

            </div> 
        </div> 
    </div> 





    </div>

    </>
  );
}


// export default PPolicy;
