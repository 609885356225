import React from "react";
import {Tabs, Tab} from "react-bootstrap";


const AboutCompany = () => {
    return (
    <div id="aboutcc" className="tabs">
        <div className="area-1">
            <div className="tabs-container2">
                <br></br>
                <p style={{marginLeft: "20px", marginRight:"20px"}}><strong>Welcome to Thompson Coaching & Consulting</strong>, where we redefine life and leadership through the transformative power of mindfulness and compassion. Our boutique coaching and consulting firm is dedicated to guiding individuals and organizations on a journey towards greater self-awareness, resilience, and purposeful living.</p>
            </div>
        </div> 
        <div className="area-2">
        
            <div className="tabs-container">
                
        
            <br />
                {/* <ul className="nav nav-tabs" id="ariaTabs" role="tablist">
                    <li className="nav-item">
                        <a className="nav-link active" id="nav-tab-1" data-toggle="tab" href="#tab-1" role="tab" aria-controls="tab-1" aria-selected="true"><i className="fas fa-th"></i> Business</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" id="nav-tab-2" data-toggle="tab" href="#tab-2" role="tab" aria-controls="tab-2" aria-selected="false"><i className="fas fa-th"></i> Expertise</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" id="nav-tab-3" data-toggle="tab" href="#tab-3" role="tab" aria-controls="tab-3" aria-selected="false"><i className="fas fa-th"></i> Quality</a>
                    </li>
                </ul> */}
                <Tabs defaultActiveKey={1} id="ariaTabs" className="nav nav-tabs">
                    <Tab className="nav-item" eventKey={1} title="Mission">
                        <div className="tab-pane" id="tab-1" role="tabpanel" aria-labelledby="tab-1">
                        <h4>Mission Statement</h4>
                        <p>At Thompson Coaching & Consulting, our mission is to cultivate a mindful and compassionate approach to life and leadership. We believe that by integrating these principles, individuals and teams can unlock their full potential, fostering a positive,  harmonious and inclusive work environment.</p>
                        
                        
                    </div> 
                    </Tab>
                    <Tab className="nav-item" eventKey={2} title="Values">
                        <div className="tab-pane" id="tab-2" role="tabpanel" aria-labelledby="tab-2">
                            <ul className="list-unstyled li-space-lg first">
                                <li className="media">
                                    <div className="media-bullet">1</div>
                                    <div className="media-body"><strong>Mindfulness: </strong>We promote a present-moment awareness that cultivates clarity, focus, and a deeper connection to oneself and others.</div>
                                </li>
                                <li className="media">
                                    <div className="media-bullet">2</div>
                                    <div className="media-body"><strong>Integrity:</strong> We uphold the highest ethical standards, ensuring trust and authenticity in all our interactions.</div>
                                    
                                </li>

                            </ul>
                            <ul className="list-unstyled li-space-lg last">
                            <li className="media">
                                    <div className="media-bullet">3</div>
                                    <div className="media-body"><strong>Compassion: </strong>Our approach is grounded in empathy, kindness, and understanding, fostering a culture of support and collaboration.</div>
                                </li>
                                <li className="media">
                                    <div className="media-bullet">4</div>
                                    <div className="media-body"><strong>Continuous Growth: </strong> We believe in the ongoing pursuit of personal and professional development, embracing change as an opportunity for learning and transformation.</div>
                                </li>

                            </ul>
                        </div> 
                    </Tab>
                    <Tab className="nav-item" eventKey={3} title="Why ?">
                        <div className="tab-pane" id="tab-3" role="tabpanel" aria-labelledby="tab-3">
                        <p><strong>Personalized Approach: </strong>Our boutique size allows us to provide individualized attention and customized programs to meet the unique needs of each client.</p>
                        <p><strong>Expertise: </strong>Our team of experienced coaches and consultants bring a wealth of knowledge in mindfulness, compassion, and leadership development.</p>
                        <p><strong>Holistic Perspective: </strong>We recognize the interconnectedness of personal and professional life, offering holistic solutions that foster well-being in all aspects.</p>

                    </div> 
                    </Tab>
                    </Tabs>

                <div className="tab-content" id="ariaTabsContent">

            


     



                </div> 


            </div> 
        </div>
    </div> 

    );
    }
  export default AboutCompany;