import React, {useState} from "react";
import {HashLink} from "react-router-hash-link"
import {Sendmail} from "../../redux/actions/MailActions"


export default function Contact () {

    const [contactInfo, setContactInfo] = useState({
        subject : 'Natasha Thomspon | Contact from Website',
        name: '',
        mail: '',
        message: ''
    });

    function updateState(e){
        e.persist()
    
        setContactInfo({
          ...contactInfo,
          [e.target.id]: e.target.value,
          [e.target.name]: e.target.value
        })
      }
    
      const handleSubmit = (contactInfo, e) => {
        e.preventDefault();
        Sendmail(contactInfo,e)

        setContactInfo({
            subject : 'Natasha Thomspon | Contact from Website',
            name: '',
            mail: '',
            message: ''
          })
      };
    

    return (
        <div id="contact" className="form-2">
        <div className="container">
            <div className="row">
                <div className="col-lg-6">
                    <div className="text-container">
                        {/* <div className="section-title">Message me</div> */}
                        <h2 style=   {{ "display": "flex"}}>Message me</h2>
                        <p>You can reach me out in any of the following social networks, or message me from here</p>
                        <ul className="list-unstyled li-space-lg">
                            {/* <li className="address"><i className="fas fa-map-marker-alt"></i>1st Ewe Street, Charlotte, NC 123123, US</li> */}
                            <li><i className="fas fa-phone"></i><a href="tel:+17046895816">+1 704 689 5816</a></li>
                            {/* <li><i className="fas fa-phone"></i><a href="tel:+17045551212">+1 704 555 1212</a></li> */}
                            
                            <br />
                            <li><i className="fas fa-envelope"></i><a href="mailto:info@natashathompson.com">info@natashathompson.com</a></li>
                        </ul>
                        {/* <h3>Follow Natasha On Social Media</h3> */}

                        {/* <span className="fa-stack">
                            <a target="_blank" rel="noreferrer" href="https://www.facebook.com/natashafayettethompson">
                                <span className="hexagon"></span>
                                <i className="fab fa-facebook-f fa-stack-1x"></i>
                            </a>
                        </span>*/}
                        <span className="fa-stack">
                            <a target="_blank" rel="noreferrer" href="https://twitter.com/coachnatasha">
                                <span className="hexagon"></span>
                                <i className="fab fa-twitter fa-stack-1x"></i>
                            </a>
                        </span> 
                        {/* <span className="fa-stack">
                            <a href="#your-link">
                                <span className="hexagon"></span>
                                <i className="fab fa-pinterest fa-stack-1x"></i>
                            </a>
                        </span> */}
                        {/* <span className="fa-stack">
                            <a target="_blank" rel="noreferrer" href="#your-link">
                                <span className="hexagon"></span>
                                <i className="fab fa-instagram fa-stack-1x"></i>
                            </a>
                        </span> */}
                        <span className="fa-stack">
                            <a target="_blank" rel="noreferrer" href="https://www.linkedin.com/in/natashafayettethompson/">
                                <span className="hexagon"></span>
                                <i className="fab fa-linkedin-in fa-stack-1x"></i>
                            </a>
                        </span>
                        {/* <span className="fa-stack">
                            <a href="#your-link">
                                <span className="hexagon"></span>
                                <i className="fab fa-behance fa-stack-1x"></i>
                            </a>
                        </span> */}
                    </div> 
                </div> 
                <div className="col-lg-6">
                    
                    {/* <!-- Contact Form --> */}
                    <form id="contactForm" data-toggle="validator" data-focus="false" onSubmit={(e) => handleSubmit(contactInfo, e)}> 
                        <div className="form-group">
                            <input type="text" className="form-control-input" id="name" required value={contactInfo.name} onChange={updateState}/>
                            <label className="label-control" htmlFor="name">Name</label>
                            <div className="help-block with-errors"></div>
                        </div>
                        <div className="form-group">
                            <input type="email" className="form-control-input" id="mail" required value={contactInfo.mail} onChange={updateState}/>
                            <label className="label-control" htmlFor="mail">Email</label>
                            <div className="help-block with-errors"></div>
                        </div>
                        <div className="form-group">
                            <textarea className="form-control-textarea" id="message" value={contactInfo.message} required onChange={updateState}></textarea>
                            <label className="label-control" htmlFor="message">Your message</label>
                            <div className="help-block with-errors"></div>
                        </div>
                        <div className="form-group checkbox">
                            <input type="checkbox" id="cterms" value="Agreed-to-Terms" required/>I agree with Natasha Thompson's's stated <HashLink to="/privacy-policy#">Privacy Policy</HashLink>
                            <div className="help-block with-errors"></div>
                        </div>
                        <div className="form-group">
                            <button type="submit" className="form-control-submit-button" style={{outline:0}}>SUBMIT MESSAGE</button>
                        </div>
                        <div className="form-message">
                            <div id="cmsgSubmit" className="h3 text-center hidden"></div>
                        </div>
                    </form>


                </div> 
            </div> 
        </div> 
    </div> 
    );
  };
   
