import React from "react";
import { Swiper, SwiperSlide } from 'swiper/react';


// import Swiper core and required modules
import SwiperCore, {
    Navigation, Autoplay
  } from 'swiper/core';
  
  // install Swiper modules
  SwiperCore.use([Navigation]);
  SwiperCore.use([Autoplay]);

const Testimonials = () => {
    return (
        <div className="testimonials-div"> 


        <div className="basic-1" id="testimonials">
            <div className="containfer" style={{textAlign: 'center'}}>
                <div className="row" >
                    <div className="col-lg-12">
                        <div className="section-title">TESTIMONIALS</div>
                        {/* <h2>Read Customer Testimonials<br /></h2>  */}
                    </div> 
                </div> 
            <div className="row">
            <Swiper navigation={true} spaceBetween={50} autoplay={{ delay: 3000 }} 
                breakpoints={{
                    // when window width is >= 640px
                    640: {
                        width: 640,
                        slidesPerView: 1,
                        },
                    // when window width is >= 768px
                    768: {
                        width: 768,
                        slidesPerView: 2,
                    },
                }}
              className="slider">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="slider-container">
                                <div className="swiper-container card-slider">
                                    <div className="swiper-wrapper">
                                        
                        
                                        <SwiperSlide className="swiper-slide">
                                            <div className="card">
                                                {/* <img className="card-image" src="assets/images/testimonial-1.jpg" alt="alternative" /> */}
                                                <div className="card-body">
                                                    <div className="testimonial-text">I am grateful for Natasha's gentle push and persistence in holding me accountable to my personal and professional goals. Not allowing me to opt out forced me to take a self inventory and "do the work" in order to achieve the outcomes identified during our initial session. I am humbled that she carved out time and partnered with me so that I can be my best self. I am walking away with a unique set of tools that I would not have been afforded or equipped with if it weren't for Natasha.</div>
                                                    {/* <div className="testimonial-author">Jude Thorn - ABC</div> */}
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                    
                
                                    
                                        <SwiperSlide className="swiper-slide">
                                            <div className="card">
                                                {/* <img className="card-image" src="assets/images/testimonial-2.jpg" alt="alternative" /> */}
                                                <div className="card-body">
                                                    <div className="testimonial-text">When the idea of a life coach was first brought to my attention, I'll admit, I was 100% skeptical. My girlfriend mentioned it to me at a time in my life when I had a lot going on: family and personal things. When I stopped to think about it and realized there are absolutely no coincidences, I knew I was meant to participate. So, I contacted Natasha, and the rest is history. I've never had any problems opening up to people, so our rapport came quite easily.</div>
                                                    {/* <div className="testimonial-author">Marsha Singer</div> */}
                                                </div>
                                            </div>        
                                        </SwiperSlide>
                                        <SwiperSlide className="swiper-slide">
                                            <div className="card">
                                                {/* <img className="card-image" src="assets/images/testimonial-2.jpg" alt="alternative" /> */}
                                                <div className="card-body">
                                                    <div className="testimonial-text">One of the best things about Natasha is that she always makes things better. Whether it's just her voice or the ways in which she will make you think, I always left our sessions feeling like I was on top of the world. She helped me birth ideas and made me rediscover my voice. For me, this experience was about growth and challenging my mindset. I realized that it would better serve me to unlearn some things I had always thought to be true and that it was ok to do that. I have so much more peace in my life, because Natasha showed me how to establish boundaries and recognize when I needed to take a step back.</div>
                                                    {/* <div className="testimonial-author">Marsha Singer</div> */}
                                                </div>
                                            </div>        
                                        </SwiperSlide>
                
                                    
                                        
                                    
                                    
                                    </div> 
                
                                
                                    {/* <div className="swiper-button-next"></div>
                                    <div className="swiper-button-prev"></div> */}
                                    
                
                                </div> 
                            </div> 
                            

                        </div> 
                    </div> 
            </Swiper> 
            </div>
        </div>
        </div>
        </div>
    );
  };
   
  export default Testimonials;