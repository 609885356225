
// import api from "../../services/api"

export const GET_PAYMENT_DETAIL = "GET_PAYMENT_DETAIL";




// export function getCredential(data) {
//   return (dispatch) => {
//     dispatch({
//       type: "GET_PAYMENT_DETAIL",
//       payload: {
//         loading: true,
//         error: false
//       }
//     })
//     api.post('mycredential', data)
//       .then(response => {
//         dispatch({
//           type: GET_PAYMENT_DETAIL,
//           payload: {...response.data, loading: false}
//         })
//       })
//       .catch(error => {
//         dispatch({
//             type: "GET_PAYMENT_DETAIL",
//             payload: {
//               loading: false,
//               error: true
//             }
//           })
//       })
//   }
// }