import React from "react";
import { InlineWidget } from "react-calendly"

// pageSettings={{
//   backgroundColor: 'ffffff',
//   hideEventTypeDetails: false,
//   hideLandingPageDetails: false,
//   primaryColor: '00a2ff',
//   textColor: '4d5055'
// }}

const Schedule = () => {
    return (

      <div id="schedule" className="counter" style={{backgroundColor: "#fff"}}>
        <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title">SCHEDULE A CALL</div>
                  <InlineWidget url="https://calendly.com/natasha_thompson/introductory-consultation?hide_gdpr_banner=1"  pageSettings={{primaryColor:"AE6136"}} styles={{height: '1000px' }}/>



                  </div> 
              </div> 
          </div> 
      </div> 

    );
  };
   
  export default Schedule;