import emailjs from '@emailjs/browser';
// require('dotenv/config');


const userid = process.env.REACT_APP_MAIL_USERID
const templateid = process.env.REACT_APP_TEMPLATEID
const serviceid = process.env.REACT_APP_SERVICEID

// https://www.mailersend.com/pricing < 3000 free mails
export function Sendmail(contactInfo,e) {
        e.preventDefault()

        const templateParams = {
            from_name: contactInfo.name,
            to_name: "natasha@natashathompson.com",
            subject:"Natasha Thomspon | New Contact from Website",
            reply_to: contactInfo.mail,
            message: contactInfo.message
        };


        emailjs.send(serviceid, templateid, templateParams, userid )
        .then((result) => {
            return (alert('Your message has been sent !'))
                
        }, (error) => {
            console.log(error);
        });


        
    //     context: { name, mail, message }
    // }, (err) => {
    //     // console.log(err)

    // })
}

