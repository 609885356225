import * as React from 'react';



function Footer() {


  return (
    <>
      <div className="footer">
        <div className="container">
            <div className="row">
                <div className="col-md-6">
                    <div className="text-container about">
                        <h4>Get Ready to Transform</h4>
                        <p className="white">Embark on a journey of self-discovery, leadership excellence, and joy with Natasha Thompson. Whether you're seeking personal growth, professional development, or a more compassionate approach to leadership, Natasha is dedicated to guiding you on a path toward a more meaningful and balanced life.</p>
                        <p className="white">Connect with Natasha and experience the profound impact of mindfulness and compassion on your life and leadership journey.</p>
                    </div> 
                </div> 
                
            </div> 
        </div> 
    </div> 


    <div className="copyright">
        <div className="container">
            <div className="row">
                <div className="col-lg-12">
                    <p className="p-small">Copyright © {new Date().getFullYear()} | Natasha Thompson</p>
                </div> 
            </div>
        </div> 
    </div> 

    </>
  );
}



export default Footer;