import React from 'react';

import { Provider } from "react-redux";
import { Router } from "react-router-dom";
import history from "./history";
import Routes from "./RootRoutes";
import { Store } from "./redux/Store";


function App() {






  return (

    

      <Provider store={Store}>

          {/* <Auth> */}
            <Router history={history}>

                {/* <AuthGuard> */}

                    <Routes />

              {/* </AuthGuard> */}
            </Router>
          {/* </Auth> */}

      </Provider>



    
  );
}

export default App;
