import {
    GET_PAYMENT_DETAIL,

  } from "../actions/PaymentActions";
  
  const initialState = {

    PaymentDetail: {
      loading: true,
      success: false,
      error: false,
      details: {}
    }
  };
  
  const CredentialReducer = function(state = initialState, action) {
    switch (action.type) {
      
      case GET_PAYMENT_DETAIL: {

        return {
          ...state,
          PaymentDetail: {...action.payload},
  

        }
        
      }


      default: {
        return {
          ...state
        };
      }
    }
  };
  
  export default CredentialReducer;
  